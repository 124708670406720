import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3d00d962 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _4aec7e39 = () => interopDefault(import('../pages/adblocker.vue' /* webpackChunkName: "pages/adblocker" */))
const _5f337350 = () => interopDefault(import('../pages/alertas.vue' /* webpackChunkName: "pages/alertas" */))
const _66660c68 = () => interopDefault(import('../pages/articulo/index.vue' /* webpackChunkName: "pages/articulo/index" */))
const _a577f368 = () => interopDefault(import('../pages/beach-comments.vue' /* webpackChunkName: "pages/beach-comments" */))
const _76f64687 = () => interopDefault(import('../pages/buscaolas-club/index.vue' /* webpackChunkName: "pages/buscaolas-club/index" */))
const _185a64e5 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _04910647 = () => interopDefault(import('../pages/copa-poisson.vue' /* webpackChunkName: "pages/copa-poisson" */))
const _2e6fc3e1 = () => interopDefault(import('../pages/email/index.vue' /* webpackChunkName: "pages/email/index" */))
const _3eeb0e5a = () => interopDefault(import('../pages/exito.vue' /* webpackChunkName: "pages/exito" */))
const _2296875c = () => interopDefault(import('../pages/favorite_beaches.vue' /* webpackChunkName: "pages/favorite_beaches" */))
const _324a7c42 = () => interopDefault(import('../pages/mi-perfil.vue' /* webpackChunkName: "pages/mi-perfil" */))
const _63cb39be = () => interopDefault(import('../pages/no-disponible.vue' /* webpackChunkName: "pages/no-disponible" */))
const _69a6bae5 = () => interopDefault(import('../pages/nueva-clave.vue' /* webpackChunkName: "pages/nueva-clave" */))
const _d29d49d2 = () => interopDefault(import('../pages/playas/index.vue' /* webpackChunkName: "pages/playas/index" */))
const _b256f2ce = () => interopDefault(import('../pages/premium.vue' /* webpackChunkName: "pages/premium" */))
const _eae76e2a = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _2cd5fd3c = () => interopDefault(import('../pages/profile-screen-1.vue' /* webpackChunkName: "pages/profile-screen-1" */))
const _cb320180 = () => interopDefault(import('../pages/rechazo.vue' /* webpackChunkName: "pages/rechazo" */))
const _2f49fd44 = () => interopDefault(import('../pages/contact/partner/index.vue' /* webpackChunkName: "pages/contact/partner/index" */))
const _7828b310 = () => interopDefault(import('../pages/historia/test-player.vue' /* webpackChunkName: "pages/historia/test-player" */))
const _dce2a212 = () => interopDefault(import('../pages/old-playas/playas2.vue' /* webpackChunkName: "pages/old-playas/playas2" */))
const _24c8f795 = () => interopDefault(import('../pages/partners/socios1.vue' /* webpackChunkName: "pages/partners/socios1" */))
const _24d70f16 = () => interopDefault(import('../pages/partners/socios2.vue' /* webpackChunkName: "pages/partners/socios2" */))
const _24e52697 = () => interopDefault(import('../pages/partners/socios3.vue' /* webpackChunkName: "pages/partners/socios3" */))
const _3df250e7 = () => interopDefault(import('../pages/prompt/successful.vue' /* webpackChunkName: "pages/prompt/successful" */))
const _f6a54d64 = () => interopDefault(import('../pages/torneos/big-toto.vue' /* webpackChunkName: "pages/torneos/big-toto" */))
const _666facd7 = () => interopDefault(import('../pages/torneos/copa-poisson-2022.vue' /* webpackChunkName: "pages/torneos/copa-poisson-2022" */))
const _667dc458 = () => interopDefault(import('../pages/torneos/copa-poisson-2023.vue' /* webpackChunkName: "pages/torneos/copa-poisson-2023" */))
const _2bbbe693 = () => interopDefault(import('../pages/torneos/torneo-longboard-2023.vue' /* webpackChunkName: "pages/torneos/torneo-longboard-2023" */))
const _33783f86 = () => interopDefault(import('../pages/torneos/viejos-lobos.vue' /* webpackChunkName: "pages/torneos/viejos-lobos" */))
const _21776c07 = () => interopDefault(import('../pages/articulo/_slug/index.vue' /* webpackChunkName: "pages/articulo/_slug/index" */))
const _934f8f3c = () => interopDefault(import('../pages/buscaolas-club/_name/index.vue' /* webpackChunkName: "pages/buscaolas-club/_name/index" */))
const _2967df89 = () => interopDefault(import('../pages/historia/_slug/index.vue' /* webpackChunkName: "pages/historia/_slug/index" */))
const _c6aaa750 = () => interopDefault(import('../pages/old-playas/_name/index.vue' /* webpackChunkName: "pages/old-playas/_name/index" */))
const _c814be1c = () => interopDefault(import('../pages/playas/_name/index.vue' /* webpackChunkName: "pages/playas/_name/index" */))
const _01376e14 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _3d00d962,
    name: "about"
  }, {
    path: "/adblocker",
    component: _4aec7e39,
    name: "adblocker"
  }, {
    path: "/alertas",
    component: _5f337350,
    name: "alertas"
  }, {
    path: "/articulo",
    component: _66660c68,
    name: "articulo"
  }, {
    path: "/beach-comments",
    component: _a577f368,
    name: "beach-comments"
  }, {
    path: "/buscaolas-club",
    component: _76f64687,
    name: "buscaolas-club"
  }, {
    path: "/contact",
    component: _185a64e5,
    name: "contact"
  }, {
    path: "/copa-poisson",
    component: _04910647,
    name: "copa-poisson"
  }, {
    path: "/email",
    component: _2e6fc3e1,
    name: "email"
  }, {
    path: "/exito",
    component: _3eeb0e5a,
    name: "exito"
  }, {
    path: "/favorite_beaches",
    component: _2296875c,
    name: "favorite_beaches"
  }, {
    path: "/mi-perfil",
    component: _324a7c42,
    name: "mi-perfil"
  }, {
    path: "/no-disponible",
    component: _63cb39be,
    name: "no-disponible"
  }, {
    path: "/nueva-clave",
    component: _69a6bae5,
    name: "nueva-clave"
  }, {
    path: "/playas",
    component: _d29d49d2,
    name: "playas"
  }, {
    path: "/premium",
    component: _b256f2ce,
    name: "premium"
  }, {
    path: "/profile",
    component: _eae76e2a,
    name: "profile"
  }, {
    path: "/profile-screen-1",
    component: _2cd5fd3c,
    name: "profile-screen-1"
  }, {
    path: "/rechazo",
    component: _cb320180,
    name: "rechazo"
  }, {
    path: "/contact/partner",
    component: _2f49fd44,
    name: "contact-partner"
  }, {
    path: "/historia/test-player",
    component: _7828b310,
    name: "historia-test-player"
  }, {
    path: "/old-playas/playas2",
    component: _dce2a212,
    name: "old-playas-playas2"
  }, {
    path: "/partners/socios1",
    component: _24c8f795,
    name: "partners-socios1"
  }, {
    path: "/partners/socios2",
    component: _24d70f16,
    name: "partners-socios2"
  }, {
    path: "/partners/socios3",
    component: _24e52697,
    name: "partners-socios3"
  }, {
    path: "/prompt/successful",
    component: _3df250e7,
    name: "prompt-successful"
  }, {
    path: "/torneos/big-toto",
    component: _f6a54d64,
    name: "torneos-big-toto"
  }, {
    path: "/torneos/copa-poisson-2022",
    component: _666facd7,
    name: "torneos-copa-poisson-2022"
  }, {
    path: "/torneos/copa-poisson-2023",
    component: _667dc458,
    name: "torneos-copa-poisson-2023"
  }, {
    path: "/torneos/torneo-longboard-2023",
    component: _2bbbe693,
    name: "torneos-torneo-longboard-2023"
  }, {
    path: "/torneos/viejos-lobos",
    component: _33783f86,
    name: "torneos-viejos-lobos"
  }, {
    path: "/articulo/:slug",
    component: _21776c07,
    name: "articulo-slug"
  }, {
    path: "/buscaolas-club/:name",
    component: _934f8f3c,
    name: "buscaolas-club-name"
  }, {
    path: "/historia/:slug",
    component: _2967df89,
    name: "historia-slug"
  }, {
    path: "/old-playas/:name",
    component: _c6aaa750,
    name: "old-playas-name"
  }, {
    path: "/playas/:name",
    component: _c814be1c,
    name: "playas-name"
  }, {
    path: "/",
    component: _01376e14,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

export const Ads = () => import('../../components/Ads.vue' /* webpackChunkName: "components/ads" */).then(c => wrapFunctional(c.default || c))
export const AlertaErrors = () => import('../../components/AlertaErrors.vue' /* webpackChunkName: "components/alerta-errors" */).then(c => wrapFunctional(c.default || c))
export const Alertas = () => import('../../components/Alertas.vue' /* webpackChunkName: "components/alertas" */).then(c => wrapFunctional(c.default || c))
export const CamerasAndSurfForecast = () => import('../../components/CamerasAndSurfForecast.vue' /* webpackChunkName: "components/cameras-and-surf-forecast" */).then(c => wrapFunctional(c.default || c))
export const Detect = () => import('../../components/Detect.vue' /* webpackChunkName: "components/detect" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const ForecastCurrent = () => import('../../components/ForecastCurrent.vue' /* webpackChunkName: "components/forecast-current" */).then(c => wrapFunctional(c.default || c))
export const GoPremium = () => import('../../components/GoPremium.vue' /* webpackChunkName: "components/go-premium" */).then(c => wrapFunctional(c.default || c))
export const GoPremium2 = () => import('../../components/GoPremium2.vue' /* webpackChunkName: "components/go-premium2" */).then(c => wrapFunctional(c.default || c))
export const GoPremiumArticles = () => import('../../components/GoPremiumArticles.vue' /* webpackChunkName: "components/go-premium-articles" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Hero = () => import('../../components/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c))
export const JoinTheClub = () => import('../../components/JoinTheClub.vue' /* webpackChunkName: "components/join-the-club" */).then(c => wrapFunctional(c.default || c))
export const LastAlert = () => import('../../components/LastAlert.vue' /* webpackChunkName: "components/last-alert" */).then(c => wrapFunctional(c.default || c))
export const LatestArticles = () => import('../../components/LatestArticles.vue' /* webpackChunkName: "components/latest-articles" */).then(c => wrapFunctional(c.default || c))
export const LoginPopup = () => import('../../components/LoginPopup.vue' /* webpackChunkName: "components/login-popup" */).then(c => wrapFunctional(c.default || c))
export const LogoutPopup = () => import('../../components/LogoutPopup.vue' /* webpackChunkName: "components/logout-popup" */).then(c => wrapFunctional(c.default || c))
export const Partners = () => import('../../components/Partners.vue' /* webpackChunkName: "components/partners" */).then(c => wrapFunctional(c.default || c))
export const PremiumHeader = () => import('../../components/PremiumHeader.vue' /* webpackChunkName: "components/premium-header" */).then(c => wrapFunctional(c.default || c))
export const PremiumPopup = () => import('../../components/PremiumPopup.vue' /* webpackChunkName: "components/premium-popup" */).then(c => wrapFunctional(c.default || c))
export const RegisterPopup = () => import('../../components/RegisterPopup.vue' /* webpackChunkName: "components/register-popup" */).then(c => wrapFunctional(c.default || c))
export const RegisterPopupPremium = () => import('../../components/RegisterPopupPremium.vue' /* webpackChunkName: "components/register-popup-premium" */).then(c => wrapFunctional(c.default || c))
export const VideoHistory = () => import('../../components/VideoHistory.vue' /* webpackChunkName: "components/video-history" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayer = () => import('../../components/VideoPlayer.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c))
export const VideoStreaming = () => import('../../components/VideoStreaming.vue' /* webpackChunkName: "components/video-streaming" */).then(c => wrapFunctional(c.default || c))
export const WeekChart = () => import('../../components/WeekChart.vue' /* webpackChunkName: "components/week-chart" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
